<template>
    <div class="footer_upper">
        <b-container fluid class="footer_container ">
            <b-row class="footer_wrap d-none d-lg-flex">
                <b-col lg="2" class="foot_wrap">
                    <b-img class="logo" :src="require('@/assets/img/Logo.png')"></b-img>
                </b-col>
                <b-col lg="9"></b-col>
            </b-row>
            <b-row class="footer_wrap">
                <b-col lg="2" class="foot_wrap d-none d-lg-block">
                    <p class="leyenda">I'm baby man bun authentic four dollar toast af wolf godard. Occupy four dollar toast disrupt helvetica organic.</p>

                    <p class="derechos">{{this.$t('footer.copyright')}}</p>
                </b-col>
                <b-col lg="6" class="foot_wrap foot_menu d-none d-lg-block">
                    <b-navbar toggleable="lg" type="dark" variant="transparent">
                        <b-navbar-toggle target="footer-collapse"></b-navbar-toggle>

                        <b-collapse id="footer-collapse" is-nav>
                            <b-navbar-nav class="mx-auto items-footer">
                                <b-nav-item :class="this.$route.name=='Somos'?'active':''" 
                                @click="router.push('somos')">
                                    {{this.$t('header.somos')}}
                                </b-nav-item>
                                <b-nav-item :class="this.$route.name=='Servicios'?'active':''" 
                                @click="router.push('servicios')">
                                    {{this.$t('header.service')}}
                                </b-nav-item>
                                <b-nav-item :class="this.$route.name=='Proyectos'?'active':''" 
                                @click="router.push('proyectos')">
                                    {{this.$t('header.projects')}}
                                </b-nav-item>
                                <b-nav-item href="#">
                                    {{this.$t('header.news')}}
                                </b-nav-item>
                                <b-nav-item href="#">
                                    {{this.$t('header.contact')}}
                                </b-nav-item>
                            </b-navbar-nav>
                        </b-collapse>
                        </b-navbar>
                </b-col>
                <b-col lg="4" class="foot_mobile">
                    <FooterSignUp />

                    <h5>{{this.$t('footer.newsletter.follow')}}</h5>
                    <b-list-group horizontal class="sociales">
                        <b-list-group-item>
                            <b-link href="#foo"><b-img :src="require('@/assets/img/svg/ico-fb.svg')"></b-img></b-link>
                        </b-list-group-item>
                        <b-list-group-item>
                            <b-link href="#foo"><b-img :src="require('@/assets/img/svg/ico-instagram.svg')"></b-img></b-link>
                        </b-list-group-item>
                        <b-list-group-item>
                            <b-link href="#foo"><b-img :src="require('@/assets/img/svg/ico-linked.svg')"></b-img></b-link>
                        </b-list-group-item>
                    </b-list-group>
                </b-col>
            </b-row>
            <b-row class="footer_wrap mt-4 d-flex d-lg-none">
                <b-col lg="2" class="foot_wrap">
                    <b-img class="logo" :src="require('@/assets/img/Logo.png')"></b-img>
                </b-col>
                <b-col md="4" sm="4" cols="7">
                    <p class="leyenda">I'm baby man bun authentic four dollar toast af wolf godard. Occupy four dollar toast disrupt helvetica organic.</p>

                    <p class="derechos">Derechos reservados 2022</p>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import router from '../../router'

import FooterSignUp from './FooterSignUp'

export default {
    components:{
        FooterSignUp,
    },
    data() {
        return {
            router,
        }
    }
}
</script>

<style scoped>

.footer_upper{
    background: var(--primario);
}
.footer_container{
    padding: 36px 2rem 40px;
    overflow: hidden;
}
.footer_container .footer_wrap .logo{
    max-height: 60px;
    object-fit: contain;
}
.footer_container .footer_wrap .navbar{
    padding-top: 0;
    padding-bottom: 0;
}
.footer_container .footer_wrap .navbar li a{
    font-size: 15px;
    font-weight: 300;
    line-height: 1.2;
    padding: 0px 30px;
    color: var(--blanco);
}

@media(max-width:1310px){
    .footer_container .footer_wrap .navbar li a{
        padding: 0px 20px;
    }
}

@media(max-width:1125px){
    .footer_container .footer_wrap .navbar li a{
        padding: 0px 10px;
    }
}

.footer_container .footer_wrap .foot_menu{
    border-left: 1px solid var(--blanco);
}
.footer_container .leyenda{
    font-size: 12px;
    font-weight: 300;
    line-height: 1.2;
    color: var(--blanco);
}
.footer_container .derechos{
    font-size: 13px;
    font-weight: 100;
    font-style: italic;
    line-height: 1.2;
    letter-spacing: 0.17px;
    color: var(--blanco);
}
.footer_container h4{
    font-size: 14px;
    font-weight: normal;
    line-height: 1.2;
    letter-spacing: 0.18px;
    color: var(--blanco);
}

.footer_container h5{
    font-size: 13px;
    font-weight: 900;
    line-height: 1.2;
    color: var(--blanco);
}
.footer_container .sociales .list-group-item{
    background: var(--transparente);
    padding: 0;
    border: none;
    padding: 5.4px;
}
.footer_container .sociales .list-group-item:first-child{
    padding-left: 0;
}
@media(max-width:576px){
    .footer_container{
        padding-left: 30px;
        padding-right: 30px;
    }
    .footer_container .foot_mobile{
        border-bottom: 1px solid var(--blanco);
        padding-bottom: 10px;
    }
}
</style>