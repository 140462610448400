<template>
  <div
    class="header_upper"
    :class="[scrolledPage ? 'scrolled' : '', navColor ? 'color' : '']"
  >
    <b-container fluid class="header_container">
      <b-navbar toggleable="lg" type="dark" variant="transparent">
        <b-navbar-brand @click="handleClickNavItem('Home')">
          <b-img
            v-if="navColor"
            class="logo"
            :src="require('@/assets/img/Logo_color.png')"
          />
          <b-img v-else class="logo" :src="require('@/assets/img/Logo.png')" />
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse" />

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="ml-auto">
            <b-nav-item
            v-for="item in items" :key="item.name"
              :class="$route.name == item.name ? 'active' : ''"
              @click="handleClickNavItem(item.name)"
            >
              {{ item.label }}
            </b-nav-item>
            <b-nav-item href="#">{{ this.$t("header.news") }}</b-nav-item>
            <b-nav-item @click="scrollBottom()">{{
              this.$t("header.contact")
            }}</b-nav-item>
            <HeaderLang />
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </b-container>
  </div>
</template>
  
<script>
import { mapGetters } from "vuex";
import router from "../../../router";
import HeaderLang from "@/components/shared/Header/Lang";

export default {
  components: {
    HeaderLang,
  },
  data() {
    return {
      router,
      scrolledPage: false,
      pageArr: ["Equipo", "Proyectos","Propiedades", "Equipo Miembro"],
    };
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
      if (this.scrollPosition > 20) {
        this.scrolledPage = true;
      } else {
        this.scrolledPage = false;
      }
    },
    scrollBottom() {
      var scrolled = document.body.scrollHeight - 1000;
      window.scroll({
        top: scrolled,
        behavior: "smooth",
      });
    },
    handleClickNavItem(routeName) {
      const locale = this.localeCode === "es" ? undefined : "en";

      this.$router.replace({
        name: routeName,
        params: {
          locale,
        },
      });
    },
  },
  computed: {
    ...mapGetters({
      localeBase: "locale/localeBase",
      localeCode: "locale/localeCode",
    }),
    navColor() {
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
      console.log(this.$route.name);
      return this.pageArr.includes(this.$route.name);
    },
    items(){

        return [
        {
            name:"Somos",
            label:this.$t("header.somos")
        },
        {
            name:"Propiedades",
            label:this.$t("header.properties"),
        },
        {
            name:"Servicios",
            label:this.$t("header.service")
        },
        {
            name:"Proyectos",
            label:this.$t("header.projects")
        },
       
      ]
      
    },
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },
  created() {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  },
};
</script>
  
  <style scoped>
.header_upper {
  position: fixed;
  top: 55px;
  left: 0;
  right: 0;
  z-index: 2222;
  transition: 0.3s ease-out all;
}
.header_upper.scrolled {
  top: 0;
  background: var(--primario);
}
.header_upper.color.scrolled {
  background: var(--blanco);
}
.header_upper.color >>> .navbar-toggler-icon {
  background-image: url("~@/assets/img/svg/ico-menu-color.svg");
}
.header_container .navbar {
  padding: 0;
}
.header_container .logo {
  max-height: 60px;
  object-fit: contain;
  cursor: pointer;
  transition: 0.2s ease-out all;
}
.header_upper .header_container li {
  position: relative;
}
.header_upper .header_container li a:before {
  content: "";
  left: 50%;
  top: 0;
  width: 0;
  max-width: 53px;
  transform: translateX(-50%);
  position: absolute;
  height: 1.3px;
  transition: 0.5s ease-out all;
}
.header_upper .header_container li.active a:before,
.header_upper .header_container li:hover a:before {
  width: 40%;
  background: var(--blanco);
}
.header_upper.color .header_container li.active a:before,
.header_upper.color .header_container li:hover a:before {
  background: var(--primario);
}
.header_upper .header_container li a {
  padding: 5px 24px;
  font-size: 17px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.2;
  color: var(--blanco);
}
.header_upper.color .header_container li a {
  color: var(--texto);
}
@media (max-width: 992px) {
  .header_upper.color .header_container .navbar-collapse li a {
    padding: 10px 20px;
    color: var(--blanco);
  }
}
@media (max-width: 576px) {
  .scrolled .header_container .logo {
    height: 50px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
</style>
  