import axios from 'axios';
import { SUPPORTED_LOCALES } from '../constants/locale';
import PublicLayout from "../layouts/public.vue";
import i18n from '../plugins/i18n';
import store from "../store";

const makeRoutes = () => {

  const path = `/:locale${getLocaleRegex()}?`

  return [
    {
      path: "",
      component: () => import("../views/Home.vue"),
      name: "Home",
    },
    {
      path: path+"/about",
      name: "About",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "about" */ "../views/About.vue"),
    },
    {
      path: path+"/somos",
      name: "Somos",
      component: () => import("../views/Somos.vue"),
    },
    {
      path: path+"/servicios",
      name: "Servicios",
      component: () => import("../views/Servicios.vue"),
    },
    {
      path: path+"/equipo",
      name: "Equipo",
      component: () => import("../views/equipo/Index.vue"),
    },
    {
      path: path+"/equipo/:id",
      name: "Equipo Miembro",
      component: () => import("../views/equipo/_Id.vue"),
    },
    {
      path: path+"/proyectos",
      name: "Proyectos",
      component: () => import("../views/Proyectos.vue"),
    },
    {
      path: path+"/propiedades",
      name: "Propiedades",
      component: () => import("../views/Propiedades.vue"),
    },
    {
      path: path+"/login",
      name: "Login",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "about" */ "../views/auth/Login.vue"),
    },
  ]
}

// Creates regex (en|fr)
function getLocaleRegex() {
    let reg = '';
    SUPPORTED_LOCALES.forEach((locale, index) => {
      reg = `${reg}${locale.code}${index !== SUPPORTED_LOCALES.length - 1 ? '|' : ''}`;
    });
    return `(${reg})`;
  }
  
  // Returns locale configuration
  function getLocale(locale = 'es') {
    return SUPPORTED_LOCALES.find(loc => loc.code === locale);
  }

  const routes = [
    ...makeRoutes(),
  ]

  export const loadLang = (lang, next) => {
    const locale = getLocale(lang);
    console.log("Locale ", locale)
    store.dispatch('locale/setLocale', locale);
    axios.get(locale.translations).then(res => {
        i18n.setLocaleMessage(locale.code, res.data || {});
    }).catch(() => {
        // TODO handle error
    }).finally(() => {
        i18n.locale = locale.code;
        if(next){
          next();
        }
    });
  }

  const beforeEnter = (to, _, next) => {
    loadLang(to.params.locale, next)
}

const publicRoutes = {
    path: `/:locale${getLocaleRegex()}?`,
    component: PublicLayout,
    beforeEnter,
    children: routes,
}

export default publicRoutes