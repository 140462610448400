export function propiedades(state) {
  return state.propiedades;
}
export function propiedad(state) {
  return state.propiedad;
}
export function propiedadActivo(state) {
  return state.propiedadActivo;
}
export function propiedadesDestacados(state) {
  return state.propiedades
    ?.filter((x) => x.destacado == true)
    .sort((a, b) => (a.posicion > b.posicion ? 1 : -1))
    .slice(0, 4);
}
