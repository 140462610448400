import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getStorage } from "firebase/storage";
import store from "./store";
const firebaseConfig = {
  apiKey: "AIzaSyCJkmcHKGz8abF8lpx4-s_34Awj-D7emNY",
  authDomain: "akko-web.firebaseapp.com",
  projectId: "akko-web",
  storageBucket: "akko-web.appspot.com",
  messagingSenderId: "336188606325",
  appId: "1:336188606325:web:2ce8dc1b7241a9eb6a753c",
};

const CreateFirebaseConfig = () => {
  initializeApp(firebaseConfig);
  const db = getFirestore();
  const auth = getAuth();
  const storage = getStorage();
  onAuthStateChanged(auth, (user) => {
    store.dispatch("auth/fetchUser", user);
  });

  return {
    db,
    auth,
    storage,
  };
};

export default CreateFirebaseConfig;
