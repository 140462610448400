<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import store from "./store";
import { SUPPORTED_LOCALES } from './constants/locale';

export default {
  data(){
    return {
      path: "/",
      locales: SUPPORTED_LOCALES
    };
  },
  computed: {
    locale() {
      return store.getters['locale/locale'];
    }
  },
  watch: {
    $route(to) {
      console.log(to)
      this.path = this.locale.base ? to.path.substring(this.locale.base.length) : to.path;
    }
  }
};
</script>

<style></style>
