export function categorias(state) {
  return state.categorias;
}

export function categoriasProyectos(state) {
  return state.categorias.filter((x) => x.seccion == "proyectos");
}

export function categoriasComercializacion(state) {
  return state.categorias.filter((x) => x.seccion == "comercializacion");
}

export function categoriasPropiedades(state) {
  return state.categorias.filter((x) => x.seccion == "propiedades");
}
