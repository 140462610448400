export function SET_PROPIEDADES(state, propiedades) {
  state.propiedades = propiedades.map((propiedad) => {
    return {
      ...propiedad,
      galeria: propiedad.galeria.sort((a, b) =>
        a.posicion > b.posicion ? 1 : -1
      ),
    };
  });
  state.propiedades.sort((a, b) => (a.posicion > b.posicion ? 1 : -1));
}

export function SET_PROPIEDAD(state, propiedad) {
  state.propiedad = propiedad;
}

export function DELETE_PROPIEDAD_IMAGE(state, imagen) {
  state.propiedad.galeria = state.propiedad.galeria.filter(
    (imagenData) => imagenData.path !== imagen.path
  );
}

export function DELETE_PROPIEDAD(state, id) {
  state.propiedades = state.propiedades.filter((propiedad) => propiedad.id !== id);
}

export function SET_PROPIEDAD_ACTIVO(state, id) {
  state.propiedadActivo = state.propiedades.find((propiedad) => propiedad.id === id);
}

export function UPDATE_PROPIEDAD_POSICION(state, { id, posicion }) {
  state.propiedades = state.propiedades.map((propiedad) => {
    if (propiedad.id === id) {
      return {
        ...propiedad,
        posicion,
      };
    }
    return propiedad;
  });
}

export function UPDATE_PROPIEDAD_DESTACADO(state, { id, destacado }) {
  state.propiedades = state.propiedades.map((propiedad) => {
    if (propiedad.id === id) {
      return {
        ...propiedad,
        destacado: destacado,
      };
    }
    return propiedad;
  });
}
