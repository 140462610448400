import Vue from "vue";
import Vuex from "vuex";
import auth from "./auth";
import categorias from "./categorias";
import comercializacion from "./comercializacion";
import equipo from "./equipo";
import locale from './locale';
import proyectos from "./proyectos";
import propiedades from "./propiedades";

Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,
  modules: {
    auth,
    comercializacion,
    proyectos,
    categorias,
    equipo,
    locale,
    propiedades,
  },
});
