import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";
import App from "./App.vue";
import CreateFirebaseConfig from "./firebase";
import i18n from './plugins/i18n';
import router from "./router";
import store from "./store";
// sweetalert2 styles
import "sweetalert2/dist/sweetalert2.min.css";
// Import Bootstrap an BootstrapVue CSS files (order is important)
import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap/dist/css/bootstrap.css";

Vue.config.productionTip = false;

//? BootsrapVue
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

//? Sweetalert2
Vue.use(VueSweetalert2);
//?firebase initialization

export const EventBus = new Vue()

export const { db, storage, auth } = CreateFirebaseConfig();
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
