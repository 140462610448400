<template>
  <div>
    <Header />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/shared/Header/index.vue";
import Footer from "@/components/shared/Footer.vue";
export default {
  components: {
    Header,
    Footer,
  },
};
</script>

<style></style>
