export function SET_CATEGORIAS(state, categorias) {
  state.categorias = categorias;
  state.categorias.push({ id: "todos", nombre: "Todos",nombreIngles:"All", seccion: "proyectos" });
  state.categorias.push({
    id: "todos",
    nombre: "Todos",
    nombreIngles:"All",
    seccion: "comercializacion",
  });
  state.categorias.push({
    id: "todos",
    nombre: "Todos",
    nombreIngles:"All",
    seccion: "propiedades",
  });
}

export function ADD_CATEGORIA(state, categoria) {
  state.categorias.push(categoria);
}

export function UPDATE_CATEGORIA(state, categoria) {
  const index = state.categorias.findIndex((c) => c.id === categoria.id);
  state.categorias.splice(index, 1, categoria);
}

export function DELETE_CATEGORIA(state, id) {
  const index = state.categorias.findIndex((c) => c.id === id);
  state.categorias.splice(index, 1);
}
