import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

import "@/assets/css/fonts.css";
import "@/assets/css/style.css";
import adminRoutes from "./admin";
import publicRoutes from './public';

Vue.use(VueRouter);



const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    publicRoutes,
    adminRoutes,
  ]
});

router.beforeEach((to, from, next) => {
  if (!store.getters["auth/user"].loggedIn) {
    if (to.meta.private) {
      next("/login");
      return;
    }
  }
  if (from.meta.private && to.path === "/login") {
    console.log("desde privado a login");
  }
  next();
});
export default router;


