import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { db, storage } from "../../main";

export async function getMiembros({ commit }) {
  try {
    const colRef = collection(db, "equipo");
    const snapshot = await getDocs(colRef);
    const miembros = snapshot.docs.map((doc) => {
      return {
        id: doc.id,
        ...doc.data(),
      };
    });
    commit("SET_MIEMBROS", miembros);
  } catch (error) {
    console.log(error);
  }
}

export async function getMiembro({ commit }, id) {
  try {
    const docRef = doc(db, "equipo", id);
    const snapshot = await getDoc(docRef);
    commit("SET_MIEMBRO", { ...snapshot.data(), id: snapshot.id });
    return true;
  } catch (error) {
    console.log(error);
    return null;
  }
}
export async function addMiembro({ commit, dispatch }, miembro) {
  try {
    const colRef = collection(db, "equipo");
    const imageData = await dispatch("saveMiembroImage", miembro.imagen);
    addDoc(colRef, { ...miembro, imagen: imageData });
    commit("ADD_MIEMBRO", miembro);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function updateMiembro({ commit, dispatch }, miembro) {
  try {
    const docRef = doc(db, "equipo", miembro.id);
    if (!Object.prototype.hasOwnProperty.call(miembro.imagen, "path")) {
      const imageData = await dispatch("saveMiembroImage", miembro.imagen);
      miembro.imagen = imageData;
    }
    updateDoc(docRef, miembro);
    commit("SET_MIEMBRO", miembro);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function deleteMiembro({ commit }, id) {
  try {
    const docRef = doc(db, "equipo", id);
    await deleteDoc(docRef);
    commit("DELETE_MIEMBRO", id);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}
export async function saveMiembroImage(_, imagen) {
  try {
    const path = `images/miembros/${imagen.name}`;
    const imageRef = ref(storage, path);
    await uploadBytes(imageRef, imagen);
    const url = await getDownloadURL(imageRef);
    return {
      url,
      path,
    };
  } catch (error) {
    console.log(error);
  }
}

export async function updatePosicion({ commit }, { id, posicion }) {
  try {
    const docRef = doc(db, "equipo", id);
    await updateDoc(docRef, { posicion });
    commit("UPDATE_MIEMBRO_POSICION", { id, posicion });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}