import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  setDoc,
  updateDoc
} from "firebase/firestore";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes
} from "firebase/storage";
import { db, storage } from "../../main";

export async function getProyectos({ commit, dispatch, rootState }) {
  try {
    await dispatch("categorias/getCategorias", null, { root: true });
    const colRef = collection(db, "proyectos");
    const snapshot = await getDocs(colRef);
    
    const proyectos = snapshot.docs.map((doc) => {

      const category = rootState.categorias.categorias.find(
        (x) => x.id == doc.data().categoria
      )

      return {
        id: doc.id,
        ...doc.data(),
        categoriaName: category.nombre,
        categoriaNameIngles:category.nombreIngles,
      };
    });
    commit("SET_PROYECTOS", proyectos);
  } catch (error) {
    console.log(error);
  }
}

export async function getProyecto({ commit }, id) {
  try {
    const docRef = doc(db, "proyectos", id);
    const snapshot = await getDoc(docRef);
    commit("SET_PROYECTO", { ...snapshot.data(), id: snapshot.id });
    return true;
  } catch (error) {
    console.log(error);
    return null;
  }
}
export async function saveProyecto({ dispatch }, desarrollo) {
  try {
    const imagenes = await Promise.all(
      desarrollo.galeria.map(async (imagen) => {
        const res = await dispatch("saveProyectoImage", imagen);
        if (res) {
          return res;
        }
      })
    );
    if (desarrollo.pdf) {
      const res = await dispatch("saveProyectoPdf", desarrollo.pdf);
      if (res) {
        desarrollo.pdf = res;
      }
    }
    const colRef = collection(db, "proyectos");
    await addDoc(colRef, {
      ...desarrollo,
      galeria: imagenes,
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}
export async function saveProyectoImage(_, imagenData) {
  try {
    const path = `images/proyectos/${imagenData.imagen.name}`;
    const imageRef = ref(storage, path);
    await uploadBytes(imageRef, imagenData.imagen);
    const url = await getDownloadURL(imageRef);
    return {
      url,
      path,
      posicion: imagenData.posicion,
    };
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function deleteProyectoImage({ commit, state }, imagen) {
  try {
    const imageRef = ref(storage, imagen.path);
    await deleteObject(imageRef);
    commit("DELETE_PROYECTO_IMAGE", imagen);

    const docRef = doc(db, "proyectos", state.proyecto.id);
    await setDoc(docRef, state.proyecto);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function updateProyecto({ dispatch }, proyecto) {
  try {
    const newImages = proyecto.galeria.filter(
      (x) => !Object.prototype.hasOwnProperty.call(x, "path")
    );
    if (newImages.length > 0) {
      const imagenes = await Promise.all(
        newImages.map(async (imagen) => {
          const res = await dispatch("saveProyectoImage", imagen);
          if (res) {
            return res;
          }
        })
      );
      proyecto.galeria = [...proyecto.galeria, ...imagenes];
    }
    proyecto.galeria = proyecto.galeria.filter((x) =>
      Object.prototype.hasOwnProperty.call(x, "path")
    );

    const docRef = doc(db, "proyectos", proyecto.id);
    await setDoc(docRef, proyecto);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function deleteProyecto({ commit }, id) {
  try {
    const docRef = doc(db, "proyectos", id);
    await deleteDoc(docRef);
    commit("DELETE_PROYECTO", id);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function updatePosicion({ commit }, { id, posicion }) {
  try {
    const docRef = doc(db, "proyectos", id);
    await updateDoc(docRef, { posicion });
    commit("UPDATE_PROYECTO_POSICION", { id, posicion });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function updateDestacado({ commit }, { id, destacado }) {
  try {
    const docRef = doc(db, "proyectos", id);
    await updateDoc(docRef, { destacado: destacado });
    commit("UPDATE_PROYECTO_DESTACADO", { id, destacado });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}
