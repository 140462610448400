import { auth } from "@/main";
import { signInWithEmailAndPassword } from "firebase/auth";
import router from "../../router";
export function fetchUser({ commit }, user) {
  commit("SET_LOGGED_IN", user !== null);
  if (user) {
    commit("SET_USER", {
      displayName: user.displayName,
      email: user.email,
      id: user.uid,
    });
    if (router.currentRoute.path === "/login") {
      router.push("/dashboard");
    }
  } else {
    commit("SET_USER", null);
  }
}
export async function login(_, { email, password }) {
  try {
    const credential = await signInWithEmailAndPassword(auth, email, password);
    console.log(credential.user);
    router.replace({ name: "Admin Comercializacion" });
  } catch (error) {
    console.log(error);
  }
}

export async function logout() {
  try {
    await auth.signOut();
    router.replace({ name: "Home" });
  } catch (error) {
    console.log(error);
  }
}
