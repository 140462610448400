export function SET_PROYECTOS(state, proyectos) {
  state.proyectos = proyectos.map((proyecto) => {
    return {
      ...proyecto,
      galeria: proyecto.galeria.sort((a, b) =>
        a.posicion > b.posicion ? 1 : -1
      ),
    };
  });
  state.proyectos.sort((a, b) => (a.posicion > b.posicion ? 1 : -1));
}

export function SET_PROYECTO(state, proyecto) {
  state.proyecto = proyecto;
}

export function DELETE_PROYECTO_IMAGE(state, imagen) {
  state.proyecto.galeria = state.proyecto.galeria.filter(
    (imagenData) => imagenData.path !== imagen.path
  );
}

export function DELETE_PROYECTO(state, id) {
  state.proyectos = state.proyectos.filter((proyecto) => proyecto.id !== id);
}

export function SET_PROYECTO_ACTIVO(state, id) {
  state.proyectoActivo = state.proyectos.find((proyecto) => proyecto.id === id);
}

export function UPDATE_PROYECTO_POSICION(state, { id, posicion }) {
  state.proyectos = state.proyectos.map((proyecto) => {
    if (proyecto.id === id) {
      return {
        ...proyecto,
        posicion,
      };
    }
    return proyecto;
  });
}

export function UPDATE_PROYECTO_DESTACADO(state, { id, destacado }) {
  state.proyectos = state.proyectos.map((proyecto) => {
    if (proyecto.id === id) {
      return {
        ...proyecto,
        destacado: destacado,
      };
    }
    return proyecto;
  });
}
