<template>
  <div>
    <h5>Dashboard Admin</h5>
    <hr />
    <b-list-group>
      <b-list-group-item
        v-for="(link, index) in links"
        :key="index"
        :to="link.url"
        class="d-flex justify-content-start align-items-center"
      >
        <b-icon :icon="link.icon" class="mr-2"></b-icon> {{ link.name }}
      </b-list-group-item>
      <b-list-group-item
        button
        @click="logout"
        class="d-flex justify-content-start align-items-center"
      >
        Cerrar Sesión <b-icon icon="box-arrow-left" class="ml-2"></b-icon>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      links: [
        {
          name: "Comercializacion",
          url: "/admin/comercializacion",
          icon: "basket3-fill",
        },
        {
          name: "Proyectos",
          url: "/admin/proyectos",
          icon: "pentagon-fill",
        },
        {
          name: "Propiedades",
          url: "/admin/propiedades",
          icon: "pentagon-fill",
        },
        {
          name: "Categorias Proyectos",
          url: "/admin/categorias-proyectos",
          icon: "tag-fill",
        },
        {
          name: "Categorias Comercializacion",
          url: "/admin/categorias-comercializacion",
          icon: "tag-fill",
        },
        {
          name: "Categorias propiedades",
          url: "/admin/categorias-propiedades",
          icon: "tag-fill",
        },
        {
          name: "Equipo",
          url: "/admin/equipo",
          icon: "people-fill",
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      logout: "auth/logout",
    }),
  },
};
</script>

<style></style>
