<template>
  <div class="container">
    <div class="row">
      <div class="col-3">
        <sidebar />
      </div>
      <div class="col">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import sidebar from "@/components/shared/sidebar.vue";
export default {
  name: "Dashboard",
  components: {
    sidebar,
  },
  methods: {
    ...mapActions({
      logout: "auth/logout",
    }),
  },
};
</script>

<style></style>
