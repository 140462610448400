export function SET_MIEMBROS(state, miembros) {
  state.miembros = miembros.sort((a, b) => (a.posicion > b.posicion ? 1 : -1));
}

export function ADD_MIEMBRO(state, miembro) {
  state.miembros.push(miembro);
}

export function SET_MIEMBRO(state, miembro) {
  state.miembro = miembro;
}

export function DELETE_MIEMBRO(state, id) {
  state.miembros = state.miembros.filter((miembro) => miembro.id !== id);
}
export function UPDATE_MIEMBRO_POSICION(state, { id, posicion }) {
  const miembro = state.miembros.find((miembro) => miembro.id === id);
  miembro.posicion = posicion;
}
