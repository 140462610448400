export const SUPPORTED_LOCALES = [{
    code: 'en',
    base: '/en',
    flag: 'us',
    name: 'English',
    translations: '/translations/en.json'
  }, {
    code: 'es',
    base: '',
    flag: 'es',
    name: 'Español',
    translations: '/translations/es.json'
  }]
  