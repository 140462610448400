import AdminLayout from "../layouts/admin.vue";

const adminRoutes = {
    path: "/dashboard",

    component: AdminLayout,
    children: [
        {
            path: "",
            component: () => import("../views/admin/comercializacion/index.vue"),
            name: "Dashboard",
            meta: { private: true },
        },
        {
            path: "/admin/comercializacion",
            name: "Admin Comercializacion",
            component: () => import("../views/admin/comercializacion/index.vue"),
            meta: { private: true },
        },
        {
            path: "/admin/comercializacion/new",
            name: "Nueva Comercializacion",
            component: () => import("../views/admin/comercializacion/new.vue"),
            meta: { private: true },
        },
        {
            path: "/admin/comercializacion/edit/:id",
            name: "Actualizar Comercializacion",
            component: () => import("../views/admin/comercializacion/edit.vue"),
            meta: { private: true },
        },
        {
            path: "/admin/proyectos",
            name: "Admin Proyectos",
            component: () => import("../views/admin/proyectos/index.vue"),
            meta: { private: true },
        },
        {
            path: "/admin/proyectos/new",
            name: "Nuevo Proyecto",
            component: () => import("../views/admin/proyectos/new.vue"),
            meta: { private: true },
        },
        {
            path: "/admin/proyectos/edit/:id",
            name: "Actualizar Proyecto",
            component: () => import("../views/admin/proyectos/edit.vue"),
            meta: { private: true },
        },
        {
            path: "/admin/propiedades",
            name: "Admin Propiedades",
            component: () => import("../views/admin/propiedades/index.vue"),
            meta: { private: true },
        },
        {
            path: "/admin/propiedades/new",
            name: "Nueva Propiedad",
            component: () => import("../views/admin/propiedades/new.vue"),
            meta: { private: true },
        },
        {
            path: "/admin/propiedades/edit/:id",
            name: "Actualizar propiedad",
            component: () => import("../views/admin/propiedades/edit.vue"),
            meta: { private: true },
        },
        {
            path: "/admin/categorias-proyectos",
            name: "Admin Categorias Proyectos",
            component: () => import("../views/admin/categorias/proyectos.vue"),
            meta: { private: true },
        },
        {
            path: "/admin/categorias-propiedades",
            name: "Admin Categorias Propiedades",
            component: () => import("../views/admin/categorias/propiedades.vue"),
            meta: { private: true },
        },
        {
            path: "/admin/categorias-comercializacion",
            name: "Admin Categorias Comercializacion",
            component: () =>
                import("../views/admin/categorias/comercializacion.vue"),
            meta: { private: true },
        },
        {
            path: "/admin/equipo",
            name: "Admin Equipo",
            component: () => import("../views/admin/equipo/index.vue"),
            meta: { private: true },
        },
        {
            path: "/admin/equipo/new",
            name: "Nuevo Miembro",
            component: () => import("../views/admin/equipo/new.vue"),
            meta: { private: true },
        },
        {
            path: "/admin/equipo/edit/:id",
            name: "Actualizar Miembro",
            component: () => import("../views/admin/equipo/edit.vue"),
            meta: { private: true },
        },
    ],
}

export default adminRoutes