import { ENDPOINT_URL } from '@/constants/api'

export const sendEmail = async (form, localeCode) => {

  const payload = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(form),
  }

  const lang = localeCode === 'es' ? '' : '_en'
  const url = `${ENDPOINT_URL}/mensaje_soporte${lang}.php`

  const res = await fetch(url, payload);

  // if (!res.ok) throw res

  // const response = await res?.json()

  // console.log(response)

  return res

}