
export default {
    state: {
      locale: {}
    },
    getters: {
      locale(state) {
        return state.locale
      },
      localeCode(state){
        return state.locale?.code
      },
      localeBase(state){
        return state.locale.base
      }
    },
    mutations: {
      setLocale(state, payload) {
        state.locale = payload
      }
    },
    actions: {
      setLocale({ commit }, payload) {
        commit('setLocale', payload)
      }
    },
    namespaced: true,
  }
