<template>
    <div class="lang_wrap">
      <b-nav-item @click="handleClick('en')" :class="localeCode=='es'? 'active':''">
        EN
      </b-nav-item>
      <b-nav-item @click="handleClick('es')" :class="localeCode=='en'? 'active':''">
        ES
      </b-nav-item>
    </div>
  </template>
  
  <script>
  import { mapGetters } from "vuex";
  import { loadLang } from '../../../router/public';
  
  export default {
    computed: {
      ...mapGetters({
        localeCode: "locale/localeCode",
      }),
      text(){
          return this.localeCode==='es' ? 'EN' : 'ES'
      }
    },
    methods:{
      handleClick(lang){
  
          const locale = lang==='es' ? undefined : lang
          loadLang(locale || 'es')
  
          this.$router.replace({
              name:this.$route.name,
              params:{
                  locale
              }
          })
  
      }
    }
  };
  </script>
  
  <style scoped>
  .lang_wrap{
    display: flex;
  }
  .header_upper .header_container li:first-child{
    border-right: 1px solid white;
  }
  .header_upper .header_container li.active{
    opacity: 0.5;
  }
  .header_upper .header_container li a {
    padding: 5px;
    font-size: 17px;
    font-weight: normal;
    font-style: normal;
    line-height: 1.2;
    color: var(--blanco);
  }
  .header_upper .header_container li a:hover,
  .header_upper .header_container li a:focus{
    color: var(--blanco);
  }
  .header_upper .header_container li a:before {
    content: "";
    left: 50%;
    top: 0;
    width: 0;
    max-width: 53px;
    transform: translateX(-50%);
    position: absolute;
    height: 1.3px;
    transition: 0.5s ease-out all;
  }
  .header_upper.color .header_container li a {
    color: var(--texto);
  }
  .header_upper.color .header_container li:first-child {
    border-color: var(--texto);
  }
  @media(max-width: 1024px){
    .header_upper .header_container li:first-child{
      padding-left: 24px;
    }
  }
  
  </style>