<template>
  <div>
    <h4>{{ this.$t("footer.newsletter.title") }}</h4>
    <b-form inline class="suscripcion" @submit.prevent="handleSubmit" >
      <b-input-group class="mb-3">
        <b-form-input v-model="correo" required type="email" />
        <b-input-group-append>
          <b-overlay :show="loading" >
            <b-button size="sm" type="submit" text="Button" variant="success">{{
              this.$t("footer.newsletter.btn")
            }}</b-button>
          </b-overlay>
        </b-input-group-append>
      </b-input-group>
    </b-form>
  </div>
</template>

<script>

import {mapGetters} from 'vuex'
import {sendEmail} from '@/helpers/email.js'

export default {
  data(){
    return {
      correo:'',
      loading:false,
    }
  },
  computed:{
    ...mapGetters({
      localeCode: "locale/localeCode",
    }),
  },
  methods:{
    async handleSubmit() {

      console.log("Send email ", this.correo)
      this.loading = true;
      
      try {
        
        const data = {
          asunto:'newsletter',
          correo:this.correo,
        }

        const res = await sendEmail(data,this.localeCode)

        console.log(res)

        this.$swal("Bien hecho", "Suscripción realizada", "success").then(() => {
          this.correo = ''
        })
        
      } catch (error) {
        this.$swal("Error", "Error al suscripbirse", "danger")
        console.log(error);
        this.$bvToast.toast("Error al suscribirse", {
          title: "Error",
          autoHideDelay: 3000,
          appendToast: true,
          variant: "danger",
        })

      } finally {
        
        this.loading = false;

      }
      
    },
  }
};
</script>

<style lang="css" scoped>

.footer_container h4{
    font-size: 14px;
    font-weight: normal;
    line-height: 1.2;
    letter-spacing: 0.18px;
    color: var(--blanco);
}

.footer_container .suscripcion .input-group{
    width: 90%
}
.footer_container .suscripcion input{
    padding: 12px;
    height: auto;
}
.footer_container .suscripcion .btn{
    font-size: 15px;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: 0.2px;
    background: var(--oscuro);
    padding: 13px 13px 13px 17px;
    border-color: var(--oscuro);
}

</style>