import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../main";

export async function getCategorias({ commit, state }) {
  try {
    if (state.categorias.length > 0) return;
    const colRef = collection(db, "categorias");
    const snapshot = await getDocs(colRef);
    const categorias = snapshot.docs.map((doc) => {
      return {
        id: doc.id,
        ...doc.data(),
      };
    });
    commit("SET_CATEGORIAS", categorias);
  } catch (error) {
    console.log(error);
  }
}

export async function createCategoria({ commit }, newCategoria) {
  try {
    const colRef = collection(db, "categorias");
    const newDoc = await addDoc(colRef, {
      nombre: newCategoria.nombre,
      nombreIngles:newCategoria.nombreIngles,
      seccion: newCategoria.seccion,
    });
    const snapshot = await getDoc(doc(colRef, newDoc.id));
    const categoria = {
      id: snapshot.id,
      ...snapshot.data(),
    };
    commit("ADD_CATEGORIA", categoria);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function updateCategoria({ commit }, categoria) {
  try {
    const docRef = doc(db, "categorias", categoria.id);
    await updateDoc(docRef, categoria);
    commit("UPDATE_CATEGORIA", categoria);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function deleteCategoria({ commit }, id) {
  try {
    const docRef = doc(db, "categorias", id);
    await deleteDoc(docRef);
    commit("DELETE_CATEGORIA", id);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}
