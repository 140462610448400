export function proyectos(state) {
  return state.proyectos;
}
export function proyecto(state) {
  return state.proyecto;
}
export function proyectoActivo(state) {
  return state.proyectoActivo;
}
export function proyectosDestacados(state) {
  return state.proyectos
    ?.filter((x) => x.destacado == true)
    .sort((a, b) => (a.posicion > b.posicion ? 1 : -1))
    .slice(0, 4);
}
